import React, { useEffect, useState } from "react";
import "./PreviousReports.scss";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import { useDispatch } from "react-redux";
import { SearchBox,
  Pagination,
  Grid,
  GridItem,
  DownloadIcon,
  SortIcon,
  noCategoriesIcon,
  ViewReportIcon,
  EditReportIcon,
  Tooltip,
  NoDataFoundIcon,
  PopUpModel,
} from "global";
import ReportService from "services/ReportService";
import { DateFormatt } from "global/utils/DateUtil";
import { PositionState, InvokeType } from "global/components/tooltip/typings";
import Excel from "exceljs";
import GenerateReport from "../generateReport/GenerateReport";
import ColumnsMap from "../columnsMap";

const BASE_CLASS = "previousReports";
const itemsPerPage = 10;

interface PreviousReportsProps {
  onEditReport: (reportId: string, email: string | null) => void;
}

const PreviousReports: React.FC<PreviousReportsProps> = ({
  onEditReport
}) => {
  const dispatch = useDispatch();

  const [reportsData, setReportsData] = useState<any[]>([]);
  const [clickedReportId, setClickedReportId] = useState<string>('');
  const [clickedEmailId, setClickedEmailId] = useState<string | null>(null);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [isSortAsc, setSort] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      await ReportService.getAll().then((res: any) => {
        const resData = res.data.data;
        resData.sort((a: any, b: any) => {
          if (isSortAsc) {
            return Date.parse(a.created) - Date.parse(b.created);
          } else {
            return Date.parse(b.created) - Date.parse(a.created);
          }
        });

        setReportsData(resData);
        setCurrentPage(1);
      });
      
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Error while fetching reports. Please try again later.`,
          timeout: 3000,
        },
      });
    }
  }

  const generateExcel = async (reportName: string, colsArr: any, userArr: any) => {
    try {
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet('Activity Track Report');

      worksheet.columns = colsArr.map((col: any) => ({
        header: ColumnsMap[`${col}` as keyof typeof ColumnsMap] || col,
        key: col,
        width: 40,
      }));

      worksheet.views = [{ state: 'frozen', ySplit: 1 }];
      worksheet.getRow(1).font = { bold: true };

      userArr.forEach((data: any) => {
        if (data.reportees && colsArr.includes("reportees")) {
          data.reportees = data.reportees.join(", ");
        }
        if (data.doj && colsArr.includes("doj")) {
          data.doj = data.doj.split("T")[0];
        }
        data.officeHours = (data.officeHours || 0) / 3600000;
        const row = colsArr.map((col: any) => data[col]);
        worksheet.addRow(row);
      });

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const url = window.URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = `${reportName}.xlsx`
      document.body.appendChild(fileLink);
      fileLink.click();
      window.URL.revokeObjectURL(url);

    } catch (ex: any) {
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: 'ERROR',
          msg: 'Error while generating excel file',
          timeout: 3000
        }
      });
    }
  };

  const downloadReport = async (reportId: string, reportName: string) => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: 'Loading...' });

      let colsArr: any[] = [];
      let userArr: any[] = [];
      await ReportService.getReportById(reportId).then((res: any) => {
        const resData = res.data.data[0];

        const parsedCols = JSON.parse(resData.selection);
        const tempCols = [
          ...parsedCols.userDetails,
          ...parsedCols.categories.map((val: any) => val.name),
          ...parsedCols.hours
        ];
        colsArr = tempCols;
        userArr = JSON.parse(resData.data);
      });

      generateExcel(reportName, colsArr, userArr);

      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: 'SUCCESS',
          msg: 'Report successfully downloaded.',
          timeout: 3000
        }
      });
      setTimeout(() => dispatch({ type: SHOWSCREENBLOCKMSG, payload: '' }), 1000);
    } catch (ex: any) {
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: 'ERROR',
          msg: 'Something went wrong. Please try again later.',
          timeout: 3000
        }
      });
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: '' });
    }
  };

  return (
    <>
      <div className={BASE_CLASS}>
        <div className={`${BASE_CLASS}-header dds-h5`}>
          Previously generated reports
        </div>

        <div className={`${BASE_CLASS}-searchContainer`}>
          <SearchBox
            className={`${BASE_CLASS}-searchbox`}
            placeholder="Search here"
            style={{ width: "350px" }}
            value={searchText}
            onChange={(val: string) => {
              setSearchText(val);
            }}
            onClear={() => {
              setSearchText("");
            }}
          />
        </div>

        {reportsData.length === 0 ?
          <div className={`${BASE_CLASS}-nosearchdata`}>
            <img src={noCategoriesIcon} alt="No reports" />
            <p>No reports available.</p>
          </div>
          :
          <>
            <div className={`${BASE_CLASS}-grid`}>
              {reportsData.filter((report: any) => (
                report.name
              )
                .toLocaleLowerCase()
                .includes(searchText.trim().toLocaleLowerCase())
              ).length > 0 ? (
                <>
                  <Grid
                    verticalSpacing={"0"}
                    columns={24}
                    className={`${BASE_CLASS}-grid-title-row dds-body`}
                  >
                    <GridItem xs={10} className={`${BASE_CLASS}-grid-title-row-col `}>
                      Report name
                    </GridItem>
                    <GridItem xs={10} className={`${BASE_CLASS}-grid-title-row-col `}>
                      Generated at
                      <img
                        onClick={() => setSort(!isSortAsc)}
                        src={SortIcon}
                        alt="sortIcon"
                        className={`cursor ${BASE_CLASS}-grid-title-row-col-icon-sort-${
                          isSortAsc ? "ASC" : "DESC"
                        }`}
                      />
                    </GridItem>
                    <GridItem xs={4} className={`${BASE_CLASS}-grid-title-row-col `}>
                      Action
                    </GridItem>
                  </Grid>

                  {(isSortAsc
                    ? reportsData?.slice().reverse()
                    : reportsData
                  ).filter((report: any) => (
                    report.name
                  )
                    .toLocaleLowerCase()
                    .includes(searchText.trim().toLocaleLowerCase())
                  )
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((item, i) => (
                      <Grid
                        key={i}
                        verticalSpacing={"0"}
                        className={`${BASE_CLASS}-grid-body-row dds-body`}
                      >
                        <GridItem xs={10} className={`${BASE_CLASS}-grid-body-row-col ellipsis`}>
                          {item.name + '.xlsx'}
                        </GridItem>
                        <GridItem xs={10} className={`${BASE_CLASS}-grid-body-row-col ellipsis`}>
                          {DateFormatt(item.created)}
                        </GridItem>
                        <GridItem xs={4} className={`${BASE_CLASS}-grid-body-row-col`}>
                          <div className={`${BASE_CLASS}-grid-body-row-col-icon`}>
                            <Tooltip
                              toolTipText={"View"}
                              tooltipPosition={PositionState.bottom}
                              tooltipInvokeType={InvokeType.hover}
                              tooltipWidth={"max-content"}
                            >
                              <img
                                src={ViewReportIcon}
                                alt="view"
                                className={`cursor ${BASE_CLASS}-grid-body-row-col`}
                                onClick={() => {
                                  setClickedReportId(item.id);
                                  setClickedEmailId(item.userEmail);
                                  setShowViewModal(true);
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              toolTipText={"Edit"}
                              tooltipPosition={PositionState.bottom}
                              tooltipInvokeType={InvokeType.hover}
                              tooltipWidth={"max-content"}
                            >
                              <img
                                src={EditReportIcon}
                                alt="edit"
                                className={`cursor ${BASE_CLASS}-grid-body-row-col`}
                                onClick={() => onEditReport(item.id, item.userEmail)}
                              />
                            </Tooltip>
                            <Tooltip
                              toolTipText={"Download"}
                              tooltipPosition={PositionState.bottom}
                              tooltipInvokeType={InvokeType.hover}
                              tooltipWidth={"max-content"}
                            >
                              <img
                                src={DownloadIcon}
                                alt="download"
                                className={`cursor ${BASE_CLASS}-grid-body-row-col`}
                                onClick={() => downloadReport(item.id, item.name)}
                              />
                            </Tooltip>
                          </div>
                        </GridItem>
                      </Grid>
                    )
                  )}
                  <div className={`${BASE_CLASS}-grid-pagination`}>
                    <Pagination
                      currentPage={currentPage || 1}
                      lastPage={Math.ceil(reportsData?.filter((report: any) => (
                        report.name
                      )
                        .toLocaleLowerCase()
                        .includes(searchText.trim().toLocaleLowerCase())
                      ).length / itemsPerPage)}
                      setCurrentPage={(item) => setCurrentPage(item)}
                    />
                  </div>
                </>
              ) : (
                <div className={`${BASE_CLASS}-nosearchdata`}>
                  <img src={NoDataFoundIcon} alt="No reports" />
                </div>
              )}
            </div>
          </>
        }
      </div>

      {showViewModal && (
        <PopUpModel
          Title="View report"
          onClickClose={() => {
            setClickedReportId('');
            setShowViewModal(false);
          }}
          closeTitle="Close"
          popupModelMainStyle={{ width: "65%", maxHeight: "90vh" }}
          headerStyle={{ color: "#0D8390" }}
        >
          <div className="view-modal">
            <GenerateReport
              editReportId={clickedReportId}
              viewOnly={true}
              singleUserReportEmail={clickedEmailId}
            />
          </div>
        </PopUpModel>
      )}
    </>
  );
};

export default PreviousReports;
