import { FC, Fragment, useContext, useState, useRef, useEffect } from "react";
import "./HoursChart.scss";
import { Grid, GridItem, SortIcon, UserIconWarn } from "global";
import { Size, Button, Kind } from "@usitsdasdesign/dds-react";
import { ThemeContext } from "theme/themeContext";
import { format_HR_MIN_SEC } from "global/utils/DateUtil";
import ProgressBar from "../../components/progressBar/ProgressBar";
import COLORSlIST from "global/constants/Colors";

interface IHoursChartProps {
  data: Record<string, any>[];
  itemKey: string;
  legends: { label: string; color: string }[];
  onClickUserView?: (user: any) => void;
}
const BASE_CLASS = "HoursChart";
const HoursChart: FC<IHoursChartProps> = ({
  data,
  itemKey,
  legends,
  onClickUserView,
}) => {
  const { themeObjState } = useContext(ThemeContext);
  const listInnerRef = useRef<any>();
  const [currPage, setCurrPage] = useState<number>(1);

  const [isSortAsc, setSort] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<
    "swipeInOut" | "totalHrs" | "billableHours"
  >("totalHrs");
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const isUser = itemKey === "name";
  // console.log(data);
  const getData = (tempData: any[]) =>
    tempData
      .slice()
      .sort((a: any, b: any) => {
        if (isSortAsc) {
          return a[sortKey] - b[sortKey];
        }
        return b[sortKey] - a[sortKey];
      })
      .slice(0, (currPage + 1) * 10);

  const handleSort = (key: "swipeInOut" | "totalHrs" | "billableHours") => {
    document
      .getElementById(`${BASE_CLASS}-body`)
      ?.scrollTo({ top: 0, behavior: "smooth" });
    if (key === sortKey) {
      setSort(!isSortAsc);
    } else {
      setSortKey(key);
      setSort(false);
    }
  };

  useEffect(() => {
    setCurrPage(1);
  }, [data]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight > scrollHeight - 10 &&
        currPage * 10 <= data.length
      ) {
        setCurrPage(currPage + 1);
      }
    }
  };

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-container`}>
        <div className={`${BASE_CLASS}-title`}>
          <Grid columns={33} className={`${BASE_CLASS}-title-row dds-label `}>
            {!isUser && (
              <GridItem
                xs={15}
                className={`${BASE_CLASS}-title-row-col`}
                style={{ padding: "10px 0" }}
              />
            )}
            {isUser && (
              <>
                <GridItem xs={7} className={`${BASE_CLASS}-title-row-col`}>
                  Team member
                </GridItem>
                <GridItem>
                  <div className={`${BASE_CLASS}-body-row-divider`} />
                </GridItem>
                <GridItem xs={3.3} className={`${BASE_CLASS}-title-row-col`}>
                  Clocked in time
                  <img
                    onClick={() => handleSort("swipeInOut")}
                    src={SortIcon}
                    alt="header"
                    className={`cursor ${BASE_CLASS}-title-row-col-icon-sort-${
                      isSortAsc && sortKey === "swipeInOut" ? "ASC" : "DESC"
                    }`}
                  />
                </GridItem>
                <GridItem>
                  <div className={`${BASE_CLASS}-body-row-divider`} />
                </GridItem>
                <GridItem xs={3} className={`${BASE_CLASS}-title-row-col`}>
                  Billable hours
                  <img
                    onClick={() => handleSort("billableHours")}
                    src={SortIcon}
                    alt="header"
                    className={`cursor ${BASE_CLASS}-title-row-col-icon-sort-${
                      isSortAsc && sortKey === "billableHours" ? "ASC" : "DESC"
                    }`}
                  />
                </GridItem>
                <GridItem>
                  <div className={`${BASE_CLASS}-body-row-divider`} />
                </GridItem>
                <GridItem xs={3} className={`${BASE_CLASS}-title-row-col`}>
                  Total hours
                  <img
                    onClick={() => handleSort("totalHrs")}
                    src={SortIcon}
                    alt="header"
                    className={`cursor ${BASE_CLASS}-title-row-col-icon-sort-${
                      isSortAsc && sortKey === "totalHrs" ? "ASC" : "DESC"
                    }`}
                  />
                </GridItem>
              </>
            )}
            <GridItem xs={17} className={`${BASE_CLASS}-title-row-col`} />
          </Grid>
        </div>
        {/* body */}
        <div
          id={`${BASE_CLASS}-body`}
          className={`${BASE_CLASS}-body ${
            isShowMore ? `${BASE_CLASS}-body-scroll` : ""
          }`}
          onScroll={onScroll}
          ref={listInnerRef}
        >
          {getData(data).map((item: any, i) => (
            <Fragment key={`${item[itemKey]}-${i}`}>
              <Grid columns={33} className={`${BASE_CLASS}-body-row dds-body`}>
                {!isUser && (
                  <GridItem
                    xs={15}
                    className={`${BASE_CLASS}-body-row-col`}
                    style={{ padding: "10px 0" }}
                  >
                    {item[itemKey]}
                  </GridItem>
                )}
                {isUser && (
                  <>
                    <GridItem
                      xs={7}
                      className={`${BASE_CLASS}-body-row-col cursor`}
                      onClick={() => {
                        if (onClickUserView) onClickUserView(item);
                      }}
                    >
                      <div className={`${BASE_CLASS}-user-container`}>
                        <div
                          className={`${BASE_CLASS}-user-image`}
                          style={{
                            backgroundColor: COLORSlIST[i % COLORSlIST.length],
                          }}
                        >
                          {/* {item?.name?.charAt(0) +
                            item?.name?.split(" ")?.pop()?.charAt(0)} */}
                          <img src={UserIconWarn} alt="user-img" />
                        </div>
                        <div className={`${BASE_CLASS}-user`}>
                          <div className={`${BASE_CLASS}-user-details`}>
                            <div className="ellipsis">{item.name}</div>
                            <div className="ellipsis dds-label-sm-light">
                              {item.email + "@deloitte.com"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                    <GridItem>
                      <div className={`${BASE_CLASS}-body-row-divider`} />
                    </GridItem>
                    <GridItem xs={3.3} className={`${BASE_CLASS}-body-row-col`}>
                      {format_HR_MIN_SEC((item?.swipeInOut || 0) / 1000)}
                    </GridItem>
                    <GridItem>
                      <div className={`${BASE_CLASS}-body-row-divider`} />
                    </GridItem>
                    <GridItem xs={3} className={`${BASE_CLASS}-body-row-col`}>
                      {item?.billableHours ? `${item?.billableHours}hr` : "NA"}
                    </GridItem>
                    <GridItem>
                      <div className={`${BASE_CLASS}-body-row-divider`} />
                    </GridItem>
                    <GridItem xs={3} className={`${BASE_CLASS}-body-row-col`}>
                      {format_HR_MIN_SEC(item?.totalHrs / 1000)}
                    </GridItem>
                  </>
                )}
                <GridItem xs={17} className={`${BASE_CLASS}-body-row-col`}>
                  <ProgressBar
                    convertion={1000}
                    data={item.timespent}
                    totalHrs={
                      +Math.max.apply(
                        Math,
                        data.map((hrs) => hrs.totalHrs || 0)
                      )
                    }
                    legends={legends}
                  />
                </GridItem>
              </Grid>
            </Fragment>
          ))}
        </div>
        {data.length > 10 && !isShowMore && (
          <div
            style={{ display: "flex", justifyContent: "end", marginTop: "1vh" }}
          >
            <Button
              size={Size.m}
              kind={Kind.primary}
              theme={themeObjState}
              width="fixed"
              label={isShowMore ? "View less" : "View all"}
              onClick={() => {
                document
                  .getElementById(`${BASE_CLASS}-body`)
                  ?.scrollTo({ top: 0, behavior: "smooth" });
                setIsShowMore(!isShowMore);
              }}
            />
          </div>
        )}
        {data.length > 0 && (
          <div className={`${BASE_CLASS}-legends-container`}>
            {legends.map((legend: { label: string; color: string }) => (
              <div
                key={legend.label}
                className={`${BASE_CLASS}-legends-legend dds-body`}
              >
                <div
                  className={`${BASE_CLASS}-legends-legend-box`}
                  style={{
                    backgroundColor: legend?.color,
                  }}
                />
                {legend.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HoursChart;
