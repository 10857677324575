import React, { FC, useState, useEffect } from "react";
import "./Dashboard.scss";
import { Dropdown } from "global";
import OrgLevel from "./orgLevel/OrgLevel";
import TeamLevel from "./teamLevel/TeamLevel";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { roleEnum } from "global/constants/Enums";

const BASE_CLASS = "dashboard";
interface OptionsInterface {
  label: string;
  value: string;
}
const teamLevelOptions: OptionsInterface[] = [
  { label: "Organisational level", value: "orgLevel" },
];

const orgLevelOptions: OptionsInterface[] = [
  { label: "Team level", value: "teamLevel" },
];
const Dashboard: FC = () => {
  const LoginUserData: IUser = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );
  const [selectedUser, setSelectedUser] = useState<any>();

  const isOrgAccess: boolean =
    // LoginUserData.entitlements?.levels.some(
    //   (item) =>
    LoginUserData.role === roleEnum.MASTER ||
    (LoginUserData.role === roleEnum.ENTITLED
      // &&
      // item.department === "" &&
      // item.subDepartment === ""
      // )
    ) || false;

  const [viewType, setViewType] = useState<OptionsInterface>(
    isOrgAccess ? teamLevelOptions[0] : orgLevelOptions[0]
  );
  const [displayOptions, setDisplayOptions] = useState<OptionsInterface[]>(
    viewType.value === "orgLevel" ? orgLevelOptions : teamLevelOptions
  );

  useEffect(() => {
    if (viewType.value === "orgLevel") {
      setDisplayOptions(orgLevelOptions);
    } else if (viewType.value === "teamLevel") {
      setDisplayOptions(teamLevelOptions);
    }
  }, [viewType]);

  const handleChangeViewType = (data: any) => {
    setViewType(data);
  };

  return (
    <div className={BASE_CLASS}>
      {!selectedUser && (
        <div className={`${BASE_CLASS}-header`}>
          <div className="dds-h4">Dashboard</div>
          {isOrgAccess ? (
            <div className={`${BASE_CLASS}-header-dropdown`}>
              <Dropdown
                defaultValue={viewType || ""}
                addLabel={true}
                labelText="Select view type"
                showValue
                isClearable={false}
                onchange={handleChangeViewType}
                placeholder={""}
                options={displayOptions}
                placeholderStyle={{ color: "#63666A" }}
                dropdownIndicatorStyle={{ color: "#000000" }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {(() => {
        switch (viewType.value) {
          case "teamLevel":
            return (
              <>
                <TeamLevel />
              </>
            );
          case "orgLevel":
            return (
              <OrgLevel
                selectedUserData={(user: any) => {
                  setSelectedUser(user);
                }}
              />
            );
        }
      })()}
    </div>
  );
};

export default Dashboard;
